import { Injectable, OnInit } from '@angular/core';
import { project, image } from '../models/portfolio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortfolioApiService implements OnInit {
projects: Array<project>;

getProjects(id:string):Observable<any>
{
  return this.http.get<Array<project>>("./assets/ProjectsData.json");
  

}

ngOnInit()
{
  this.http.get<Array<project>>("./assets/ProjectsData.json").subscribe(data=>{this.projects = data});

}


  constructor(private http: HttpClient) {
   }
}
