import { Component, OnInit,AfterContentInit } from '@angular/core';
declare function initWrapperFunction(): any;
@Component({
  selector: 'about',
  templateUrl: './about.component.html'
})


export class AboutComponent implements OnInit, AfterContentInit {
  ngAfterContentInit(): void {
    initWrapperFunction();
  }

  constructor() { }

  ngOnInit() {
  }
  AfterContentInit()
  {}
}
