import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { home } from '../models/home';

@Injectable({
  providedIn: 'root'
})
export class HomeService implements OnInit {
homes: Array<home>;

getHomeData():Observable<any>
{
  return this.http.get<Array<home>>("./assets/Home.json");
  

}

ngOnInit()
{
  this.http.get<Array<home>>("./assets/Home.json").subscribe(data=>{this.homes = data});

}


  constructor(private http: HttpClient) {
   }
}