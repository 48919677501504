import { Component, OnInit,AfterContentInit, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { home } from '../models/home';
import { HomeService } from '../services/home.service';
declare function initWrapperFunction(): any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit,AfterContentInit,AfterViewInit {
  ngAfterViewInit(): void {
    this.things.changes.subscribe(t => {
      initWrapperFunction();
    })
  }
  public myHomeData: Array<home>;
  api:HomeService;
  constructor(api:HomeService) { this.api = api; }
  @ViewChildren('slider') things: QueryList<any>;
  ngOnInit() {
    this.api.getHomeData().subscribe((data: Array<home> )=>{this.myHomeData =data;});
  }
  
  ngAfterContentInit(): void {
    
  }
}
