import { Component, OnInit,AfterContentInit  } from '@angular/core';
declare function initWrapperFunction(): any;
@Component({
  selector: 'contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit, AfterContentInit {
  ngAfterContentInit(): void {
    initWrapperFunction();
  }
  constructor() { }

  ngOnInit() {
  }

}
