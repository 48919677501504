import { Component, OnInit, AfterViewInit, AfterContentInit, Input, ViewChildren, QueryList } from '@angular/core';
import { project } from '../models/portfolio';
import { PortfolioApiService } from '../services/portfolio-api.service';
import { ActivatedRoute } from '@angular/router';
declare function initWrapperFunction(): any;
declare function n(): any;
@Component({
  selector: 'portfolio/:id',
  templateUrl: './portfolio.component.html'
})
export class PortfolioComponent implements OnInit, AfterViewInit,AfterContentInit {
  ngAfterContentInit(): void {
  }

  @ViewChildren('images') things: QueryList<any>;
  @ViewChildren('mainImage') things1: QueryList<any>;
  ngAfterViewInit (): void 
  {
    this.things.changes.subscribe(t => {
      n();
    })
    this.things1.changes.subscribe(t => {
      initWrapperFunction();
    })
    
  }

  public myProject: project;
  api:PortfolioApiService;
  constructor(api:PortfolioApiService,private route: ActivatedRoute) { 
    this.api=api;
  }

  ngOnInit() {
    this.api.getProjects(this.route.snapshot.params.id).subscribe((data: Array<project> )=>{this.myProject =data.find(x=>x.id == this.route.snapshot.params.id);
      
    
    });

  }

}
