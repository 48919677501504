import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { menu } from '../models/menu';

@Injectable({
  providedIn: 'root'
})
export class MenuService implements OnInit {
menus: Array<menu>;

getMenus():Observable<any>
{
  return this.http.get<Array<menu>>("./assets/menu.json");
  

}

ngOnInit()
{
  this.http.get<Array<menu>>("./assets/menu.json").subscribe(data=>{this.menus = data});

}


  constructor(private http: HttpClient) {
   }
}