import { Component, OnInit, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { menu } from '../models/menu';

declare function initWrapperFunction(): any;
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit,AfterViewInit {
  public myMenus: Array<menu>;
  api:MenuService;

  constructor(api:MenuService) { this.api = api; }
  @ViewChildren('menuDiv') things: QueryList<any>;
  ngAfterViewInit (): void 
  {
   
    
  }


  ngOnInit() {
    this.api.getMenus().subscribe((data: Array<menu> )=>{this.myMenus =data;});
}

}
